window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');
require('../../public/js/scripts/bootstrap.bundle.min');
require('popper.js/dist/popper.min');
require('owl.carousel/dist/owl.carousel.min');
require('../../public/js/scripts/Animated-Type-Heading');
const {times} = require("lodash/util");
import 'inputmask';


// -------------------------------------------*-*-**-*-*-**-*-

// -------------------------------------------------------// -------------------------------------------------------// -------------------------------------------------------

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
    $('.toast').toast('show');

    let multiImgPreview  = function(input, imgPreviewPlaceholder) {
        if (input.files) {
            let filesAmount = input.files.length;
            for (let i = 0; i < filesAmount; i++) {
                let reader = new FileReader();
                reader.onload = function(event) {
                    $($.parseHTML('<img>')).attr('src', event.target.result).appendTo(imgPreviewPlaceholder).addClass('img-fluid img-thumbnail ml-2 mt-2');
                }
                reader.readAsDataURL(input.files[i]);
            }
        }
    };
    $('#img_url').on('change', function() {
        multiImgPreview(this, 'div.imgPreview');
    });

    if($('table').length > 0){
        $('table').DataTable({
            "autoWidth": true,
            "info": true,
            "ordering": true,
            "pagingType": "full_numbers",
            "language": {
                "lengthMenu": "Mostrar _MENU_ registro por página",
                "zeroRecords": "Nada encontrado - Desculpe",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "Nenhum registro encontrado",
                "infoFiltered": "(Filtrado de _MAX_ registros totais)",
                "search": "Busca",
                "paginate": {
                    "next": "<i class='fa fa-angle-right' aria-hidden='true'></i>",
                    "last": "<i class='fa fa-angle-double-right' aria-hidden='true'></i>",
                    "first": "<i class='fa fa-angle-double-left' aria-hidden='true'></i>",
                    "previous": "<i class='fa fa-angle-left' aria-hidden='true'></i>"
                }
            }
        });
    }

});

// // ---------------------------------------nav------------------------------

$(document).ready(function (){
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });


    // --------------------------------------------------------Modal Retorno Busca------------------------------------------
    $('#searchHelp').modal('show');
    $('#searchBtnClose').on('click', function (){
        $("#searchHelp").modal('hide');
    });
});

// -------------------------------------------BARRA DE TEMPO DO CARROSEL-------------------

$(document).ready(function () {
    $("#carouselExampleFade").on("slide.bs.carousel", function (event) {
        $(".barra", this).removeClass("anima").css("width", "0%");
    }).on("slid.bs.carousel", function (event) {
        $(".barra", this).addClass("anima").css("width", "100%");
    });
    $(".barra", "#carouselExampleFade").css("width", "100%");
});

// ---------------------------------- Lançamentos 2021-----------------
// ----------------------OWL-carousel-----------------------

if (document.getElementById('carousel-lancamentos')){

    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:40,
        nav:true,
        navText : [
            null,
            '<div class="owl-next"><i class="fas fa-chevron-right"></i></div>'
        ],
        center:true,
        autoHeight:true,
        lazyLoad: false,
        lazyLoadEager: true,
        autoWidth:true,
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    })
}



let owlder = $('#owl-carousel-der');
owlder.owlCarousel({
    items: 1,
    nav:true,
    loop:true,
    center: true,
    margin: 10,
    navText : [
       null,
        '<div class="owl-next"><i class="fas fa-chevron-right"></i></div>'
    ]

});
$('#owl-carousel-der .owl-nav').addClass('owl-nav-der');

// ------------------------------------ Controla tempo do Carrocel HOME PRINCIPAL -------------------

// PROGRAMADO PARA 10 SEGUNDOS

$('.villa-home-crsButton').each(function () {
    $(this).on("click", function (){
        let time = $(this).data('bs-slide-to');
        valorTeste(time*1);
    });

})

let intervalo = 0

function valorTeste(valorBotao) {

    if (valorBotao === undefined) {
        intervalo = 0;
    } else if (valorBotao === 0) {
        intervalo = 0;
    } else if (valorBotao === 1) {
        intervalo = 10;
    } else if (valorBotao === 2) {
        intervalo = 20;
    } else if (valorBotao === 3) {
        intervalo = 30;
    } else if (valorBotao === 4) {
        intervalo = 40;
    } else if (valorBotao === 5) {
        intervalo = 50;
    }
}
if($('#b0').length > 0) {
    setInterval(function () {
        // console.log('Segundos: ' + intervalo)  // mostra os segundos
        intervalo++

        if (intervalo === 10) {
            document.getElementById('b1').click();
        } else if (intervalo === 20) {
            document.getElementById('b2').click();
        } else if (intervalo === 30) {
            document.getElementById('b3').click();
        } else if (intervalo === 40) {
            document.getElementById('b4').click();
        } else if (intervalo === 50) {
            document.getElementById('b5').click();
        } else if (intervalo === 60) {
            document.getElementById('b0').click();
            intervalo = 0
        }
    }, 1000)
}

// -------------------------------------------------Animacao titulos das pagians----------------------------------------

if (window.innerWidth < 768) {
    $('[data-bss-disabled-mobile]').removeClass('animated').removeAttr('data-aos data-bss-hover-animate');
}

/// -------------------------------------------------Sidebar------------------------------------------------------------
$("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
});

// -------------------------------------------------Image Preview-------------------------------------------------------

$('#categoria').on('change', function (){
    let valueOptCat = $('#categoria option:selected').val();
    if(valueOptCat == 'lan'){
        $('#fileHelp span').html("800x1080 <i class='fa fa-info-circle'></i>");
        $('#codclc').prop('disabled', false);
    }else{
        $('#fileHelp span').html("1920x1080 <i class='fa fa-info-circle'></i>");
        $('#codclc').prop('disabled', true);
    }

    if(valueOptCat == 'ban' || valueOptCat == 'lan'){
        $('#descricao').prop('disabled', true);
    }else{
        $('#descricao').prop('disabled', false);
    }
})

$('#posicao').on('change', function (){
    let valueOptPos = $('#posicao option:selected').val();
    if(valueOptPos == '1' || valueOptPos == '8'){
        $('#fileHelp span').html("800x1080 <i class='fa fa-info-circle'></i>");
    }else{
        $('#fileHelp span').html("1920x1080 <i class='fa fa-info-circle'></i>");
    }
})


$('#img_url').on('change', function() {

    let filesCount = $(this)[0].files.length;
    let textbox = $(this).prev();

    if (filesCount === 1) {
        let fileName = $(this).val().split('\\').pop();
        textbox.text(fileName);
    } else {
        textbox.text(filesCount + ' files selected');
    }

    if (typeof (FileReader) != "undefined") {
        let dvPreview = $("#divImageMediaPreview");
        dvPreview.html("");
        $($(this)[0].files).each(function () {
            let file = $(this);
            let reader = new FileReader();
            reader.onload = function (e) {
                let img = $("<img />");
                img.addClass('img-fluid figure-img img-thumbnail');
                img.attr("style", "width: 200px; height:150px");
                img.attr("src", e.target.result);
                dvPreview.append(img);
            }
            reader.readAsDataURL(file[0]);
        });
    } else {
        alert("This browser does not support HTML5 FileReader.");
    }

});

$('#banner_url').on('change', function() {

    let filesCount = $(this)[0].files.length;
    let textbox = $(this).prev();

    if (filesCount === 1) {
        let fileName = $(this).val().split('\\').pop();
        textbox.text(fileName);
    } else {
        textbox.text(filesCount + ' files selected');
    }

    if (typeof (FileReader) != "undefined") {
        let dvPreview = $("#divImageMediaPreview2");
        dvPreview.html("");
        $($(this)[0].files).each(function () {
            let file = $(this);
            let reader = new FileReader();
            reader.onload = function (e) {
                let img = $("<img />");
                img.addClass('img-fluid figure-img img-thumbnail');
                img.attr("style", "width: 200px; height:150px");
                img.attr("src", e.target.result);
                dvPreview.append(img);
            }
            reader.readAsDataURL(file[0]);
        });
    } else {
        alert("This browser does not support HTML5 FileReader.");
    }

});


// ------------------------------------------------Cep Cadastro de Lojas----------------------------------------------------
function clear_cep() {
    $("#endereco").val("");
    $("#bairro").val("");
    $("#cidade").val("");
    $("#estado").val("");
}

$("#cep").blur(function() {
    let cep = $(this).val().replace(/\D/g, '');

    if (cep != "") {
        let validatecep = /^[0-9]{8}$/;

        if(validatecep.test(cep)) {

            $("#address").val("...");
            $("#district").val("...");
            $("#city").val("...");
            $("#state").val("...");
            $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(ret) {

                if (!("erro" in ret)) {
                    $("#endereco").val(ret.logradouro);
                    $("#bairro").val(ret.bairro);
                    $("#cidade").val(ret.localidade);
                    $("#estado").val(ret.uf);
                    $("#numero").focus();

                }else {
                    clear_cep();
                    $("#cep").focus()
                    alert("CEP não encontrado.");
                }
            });
        }else {
            clear_cep();
            $("#cep").focus();
            alert("Formato de CEP inválido.");
        }
    }else {
        clear_cep();
        $("#cep").focus()
    }
});
// ------------------------------------------------Mascaras----------------------------------------------------
/* Mask Input */

let cep = document.getElementById('cep');
let phone = document.getElementById('telefone');
let number = document.getElementById('numero');
if(cep) Inputmask({"mask": "99999-999"}).mask(cep);
if(phone) Inputmask({"mask": "+99 (99) 99999-9999"}).mask(phone);
if(number) Inputmask({"mask": "99999999"}).mask(number);


// ------------------------------------------------Password View----------------------------------------------------
$("#show_hide_password").on('click', function(event) {
    event.preventDefault();
    if($('#password').attr("type") == "text"){
        $('#password').attr('type', 'password');
        $('#password-confirm').attr('type', 'password');
        $('#show_hide_password i').removeClass( "fa-eye" );
        $('#show_hide_password i').addClass( "fa-eye-slash" );
    }else if($('#password').attr("type") == "password"){
        $('#password').attr('type', 'text');
        $('#password-confirm').attr('type', 'text');
        $('#show_hide_password i').removeClass( "fa-eye-slash" );
        $('#show_hide_password i').addClass( "fa-eye" );
    }
});
// ------------------------------------------------Button Admin Upload--------------------------------------------------
$('#btnUpload').on('click', function () {
    $('#btnUpload').hide();
    $('#btnUploadOn').slideDown('fast');
})

// --------------------------------------------------------BUSCA LOJA --------------------------------------------------


    $('#btnBuscaLoja').on('click', function () {
        $('#buscaLoja').toggle("slow");
        $('#buscaLoja2').toggle("slow");
        $('#resultLoja').toggle("slow");
        $('#resultLoja2').toggle("slow");



        let estado = $('#formBuscaEst').val();
        let cidade = $('#formBuscaCid').val();
        let url = "/busca-loja/" + estado + "/" + cidade;

        if (estado === '' && cidade === '') {

            alert('Estado ou cidade nao incerido')

        } else {

            $.get(url, function (data) {
                $('#resultLoja').html('')
                data.forEach(obj => {
                    Object.entries(obj).forEach(([key, value]) => {
                        $('#resultLoja').append(

                            "<div class='resultPara text-white mt-5 mb-1 ml-1 p-2' style=' width: 260px; border:1px solid rgba(255,255,255,0.11); float: left'>" +
                            "<h1 class='text-light'>" + value.nome +"</h1>" +
                            "<div class='cor-borda d-none d-xl-block my-3' style='margin-left: 0'></div>" +
                            "<div>" +
                            "<p class='text-light' style='font-size: 14px'><i class=\"fas fa-phone-alt mr-1\"></i>" + value.telefone + "</p>" +
                            "<p class='text-light' style='font-size: 14px'><i class=\"far fa-envelope mr-1\"></i>" + value.email + "</p>" +
                            "<p class='text-light' style='font-size: 14px'><i class=\"fas fa-map-marker-alt mr-1\"></i>" + value.endereco + ", " + value.numero + ", " + value.complemento + ", " + value.bairro + ", " + value.cep + "</p>" +
                            "<p class='text-light' style='font-size: 18px'><i class=\"fas fa-map-pin mr-1\"></i>" + value.cidade + "</p>" +
                            "</div>" +
                            "</div>"
                        )
                    });
                });
            })
        }
    })

    $('#btnVoltaLoja').on('click', function () {
        $('#resultLoja').toggle("slow");
        $('#resultLoja2').toggle("slow");
        $('#buscaLoja').toggle("slow");
        $('#buscaLoja2').toggle("slow");
    })



// ----------------------------------------------- home link ban---------------------------------------

if (document.getElementById('link_ban')){
    let link_banR = document.getElementById('link_ban');
    let cateBan = document.getElementById('categoria').value;

    if (cateBan !== 'ban'){
        link_banR.style.display = 'none'
    }
}

$("#categoria").on("click", function () {

    let link_banR = document.getElementById('link_ban');
    let cateBan = document.getElementById('categoria').value;

    console.log(cateBan)

    if (cateBan === 'ban'){
        link_banR.style.display = 'block'
    }else{
        link_banR.style.display = 'none'
    }
})

